.autoComplete-root {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 8px;
    background-color: var(--buttonsBackground);
    color: var(--textColor);
}

.MuiInputBase-input,
.MuiFormLabel-root,
.MuiSvgIcon-root {
    color: var(--textColor) !important;
}

.MuiAutocomplete-popper {
    z-index: 50000 !important;
}

.MuiAutocomplete-paper {
    position: relative;
    top: 10px;
    border-radius: 12px !important;
}

div.MuiAutocomplete-listbox,
ul.MuiAutocomplete-listbox,
.MuiAutocomplete-listbox > .ReactVirtualized__Grid {
    padding: 0;
    color-scheme: var(--colorScheme);
    background-color: var(--dropdownOptionBlurred);
}

.MuiAutocomplete-option[aria-selected="true"] {
    &:not(:hover) {
        background-color: var(--dropdownOptionBlurred) !important;
    }
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--buttonsBorderColor) !important;
}

.autoComplete-option {
    background-color: var(--dropdownOptionBlurred);
    color: var(--textColor);
    min-height: unset !important;
    //border: 1px solid var(--borderColorSearchBar);
    gap: 3px;
}

label.MuiFormLabel-root.MuiInputLabel-root {
    background-color: unset !important;
}

.Mui-disabled {
    color: var(--textColor) !important;
    opacity: 50%;
}

.Mui-focused {
    &.MuiAutocomplete-option {
        background-color: var(--dropdownOptionFocused) !important;
    }
    color: var(--textColor) !important;
}

.MuiOutlinedInput-root {
    padding: 3px 9px !important;
}

@media (max-width: 500px) {
    .MuiAutocomplete-endAdornment {
        top: unset !important;
    }
}