.selectable {
    cursor: pointer;
}

.grid_container {
    //max-width: 55%;
    //min-width: 55%;
    //width: 55%;
    //margin: 0 auto;  //
    max-width: 1600px; //1300
    height: 100%;
    a {
        text-decoration: none;
    }
}

.grid {
    display: flex;
    //justify-content: center;
    overflow-x: hidden !important;
    overflow-y: overlay !important;
    scrollbar-width: none;
}

.ReactVirtualized__Grid__innerScrollContainer {
    margin-left: 4px;
}

.mini-card-wrapper {
    width: 100%;
    height: 100%;
    padding: 1px;
    background-color: var(--gridItemsBackgroundColor) !important;
    border: 1px solid var(--borderColor2);
    border-radius: 4px;
    &:hover {
        border: 1px solid var(--buttonsBorderColor);
    }
}

.card-wrapper {
    width: 100%;
    height: 100%;
    padding: 5px;
    background-color: var(--gridItemsBackgroundColor) !important;
    border: 1px solid var(--borderColor2);
    border-radius: 4px;
    &:hover {
        border: 1px solid var(--buttonsBorderColor);
    }
}

.softer {
    background-color: var(--rocket-softer);
}

.not-softer {
    background-color: var(--background-shadow);
}

.round-border {
    border-radius: 12px;
}

.rocket-card-wrapper {
    background-color: var(--gridItemsBackgroundColor2) !important;
}

.card-wrapper-padding {
    padding: 4px;
}

.mini-card-wrapper-padding {
    padding: 1px;
}

@media (min-width: 500px) {
    a:hover,
    button:hover,
    .selectable:hover {
        filter: var(--hover-brightness);
    }
}

@media (max-width: 1600px) {
    .grid_container {
        max-width: 1600px;
    }
}

@media (max-width: 768px) {
    .grid_container {
        max-width: 100%;
    }
}

@media (max-width: 599.9px) {
    .card-wrapper-padding {
        padding: 2px;
    }

    .ReactVirtualized__Grid__innerScrollContainer {
        margin-left: 1px;
    }
}

@media (max-width: 435px) {
    .card-wrapper {
        padding: 1px;
    }
}