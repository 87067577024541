.layout {
    height: 100%;
    width: 100%;
    max-width: 1600px;
    //overflow-y: scroll;
    padding-top: 52.13px;
}

.pokedex-layout {
    height: 100%;//calc(100% - 75px);
    width: 100%;
    //max-width: 1300px;
    //max-width: 65%;
    display: flex;
    flex-direction: column;
    padding-top: 52.13px;
    max-width: 1600px;
}

.bordered-sides {
    border-right: 1px solid var(--borderColor2);
    border-left: 1px solid var(--borderColor2);
}

.navigation-header {
    top: 0;
    margin: 0;
    padding: 0 12px 0 12px;
    z-index: 5;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    ul {
        display: flex;
        align-items: center;
        padding: 0;
        gap: 6px;
        list-style: none;
        margin: 6px auto;
    }
}

.navigation-header.leagues {
    justify-content: center;
}

.navigation-header.extra-gap {
    ul {
        gap: 6px;
    }
}

.without-background {
    background-color: unset !important;
}

.header-tab {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0;
    cursor: pointer;
    padding: 3px 10px;
    color: var(--buttonsTextColor);
    border: 1px solid var(--borderColor2);
    font-weight: 700;
    text-decoration: none;
    border-radius: 12px;
    height: 35.6px;
    white-space: nowrap;
    &:hover {
        border: 1px solid var(--buttonsBorderColor);
    }

    &.league-picker {
        height: unset;
    }

    &.no-full-border {
        border: 1px !important;
        border-radius: 0px;

        &.selected {
            border-bottom: 2px solid var(--buttonsBorderColor) !important;
        }
    }
}

.pokedex {
    height: calc(100% - 47.6px);
    //overscroll-behavior: unset !important;
    overflow-x: hidden;
    overflow-y: hidden; //overlay
    //max-height: calc(100vh - 133px);
    //padding-top: 4px;
    border-top: 1px solid var(--borderColor2);
    box-shadow: inset 0px -2px 0px -2px rgba(0,0,0,0.2);
    //padding-bottom: 9px;
    border-right: 1px solid var(--borderColor2);
    border-left: 1px solid var(--borderColor2);
}

@media (max-width: 500px) {
    .navigation-header.extra-gap {
        ul {
            gap: 6px;
        }
    }

    .navigation-header {
        padding: 0px;
    }

    .pokedex-layout {
        padding-top: 49.25px;
        //height: calc(100% - 49.25px);
    }

    .layout {
        padding-top: 49.25px;
    }
}

@media (min-width: 500px) {
    .header-tab {
        &.no-full-border {
            &:hover {
                border-bottom: 2px solid var(--buttonsBorderColor) !important;
            }
        }
    }
}