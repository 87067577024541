.pvp-leagues-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.default-padding {
    padding: 6px;
}

.pvp-stats-2 {
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    width: fit-content;
    opacity: 60%;
    border: 1px solid var(--borderColor2);
    border-radius: 12px;
    padding-right: 3px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 6px;
    gap: 6px;
    align-items: center;
    color: #fff;
    text-shadow: 1px 5px 5px rgba(0,0,0,.25);
    cursor: pointer;
    &.selected {
        opacity: 100%;
    }
}

.pokemon-ivs-ranked-2 {
    font-weight: 500;
}

.pvp-title-2 {
    display: grid;
    grid-template-columns: 1fr 1fr 5fr;
    align-items: center;
    gap: 5px;
    width: 100%;
    h3, h4 {
        margin: 0;
        width: 100%;
    }
}

.normal-entry {
    background: var(--empty-entry);
}

.pvp-title-item {
    place-self: center;
    width: 30px;
    height: 30px;
}

.pvp-title-item-small {
    place-self: center;
    width: 20px;
    height: 20px;
}

.pvp-img-2 {
    color: transparent;
}

@media (max-width: 500px) {
    .default-padding {
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media (max-width: 360px) {
    .pvp-title-2 {
        gap: 0px;
    }
}