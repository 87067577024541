.pokemon-content {
    font-size: 0.875rem;
    //padding-right: 12px;
    //padding-left: 12px;
    padding-bottom: 52.13px;
    //margin-bottom: 65px;
    background: var(--bodyBackgroundColor);
}

@media (max-width: 500px) {
    .pokemon-content {
        padding-bottom: 80px;
        padding-right: 0px;
        padding-left: 0px;
    }
}