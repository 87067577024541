.events-header-image-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 120px;
    width: 100%;
    img {
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
}

@media (min-width: 500px) {
    .events-header-image-container {
        height: 215px;
    }
}