

.pvp-stats {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 6px;
    gap: 6px;
    align-items: center;
}

.unranked-pvp-stats {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 6px;
    align-items: center;
    gap: 6px;
}

.raid-stats {
    height: 100%;
    display: grid;
    grid-gap: 6px;
    gap: 6px;
}

.double-card {
    grid-template-columns: 1fr 1fr;
}

.triple-card {
    grid-template-columns: 1fr 1fr 1fr;
}

.pvp-stats-column {
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 5px;
    border-radius: 12px;
    border: 1px solid var(--borderColor2);
    border-radius: 12px;
    font-weight: 700;
    * {
        z-index: 1;
    }
}

.pvp-gap {
    gap: 5px;
    grid-gap: 5px;
}

.raid-gap {
    gap: 4px;
    grid-gap: 4px;
}

.pvp-labels {
    display: flex;
    flex-direction: column;
    gap: 6px;

    header {
        color: #fff;
        text-shadow: -1px 0 #00000040, 0 1px #00000040, 1px 0 #00000040, 0 -1px #00000040;
        font-weight: 700;
    }
}

.template-pvp-labels {
    display: flex;
    flex-direction: column;
    gap: 6px;

    header {
        color: #fff;
        text-shadow: -1px 0 #00000040, 0 1px #00000040, 1px 0 #00000040, 0 -1px #00000040;
        font-weight: 700;
    }

    >div {
        display: grid;
        grid-template-rows: calc(50% - 3px) calc(50% - 3px);
        gap: 6px;
    }
}

.without-shadow {
    box-shadow: none !important;
}

.pvp-scores {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 6px;
    border-radius: 12px;
    background-color: rgba(0,0,0,.5);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
}

.spaced {
    justify-content: space-between;
}

.pvp-entry {
    border-radius: 12px;
    background-color: var(--background-shadow);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    display: flex;
    justify-content: center;
    flex-grow: 1;
    padding: 4px 6px 4px 6px;
    z-index: 30000;
}

.smooth {
    background-color: var(--background-shadow-soft);
}

.rank-title {
    background-color: var(--background-shadow);
}

.pvp-entry-content {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    color: inherit;
    text-decoration: none;
    font-weight: 700;
    gap: 3px;
    padding: 3px 0;
    &.no-padding {
        padding: 0 0;
    }
    sub {
        opacity: .55;
    }
}

.raid-rank-types,
.pvp-entry {
    sub {
        opacity: .55;
    }
}

.potential {
    display: flex;
    align-items: flex-end;
}

.centered-text {
    text-align: center;
}

.needed-resources {
    display: flex;
}

.white-text {
    color: #fff;
    text-shadow: -1px 0 #00000040, 0 1px #00000040, 1px 0 #00000040, 0 -1px #00000040;
}

.with-brightness {
    filter: var(--extra-brightness);
}

.with-permanent-brightness {
    filter: brightness(1.4);
}

.cp-and-level {
    opacity: 0.9;
}

.custom-pokemon {
    width: 100%;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.15);
    padding-top: 2px;
    padding-bottom: 4px;
}

.type-attack {
    display: flex;
    flex-direction: row;
    gap: 2px;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.pokemon-attack {
    img {
        align-self: center;
        width: 10px;
        height: 10px;
    }
}

.percentile {
    opacity: 0.67;
}

.pvp-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    width: 100%;
    h3, h4 {
        margin: 0;
        width: 100%;
    }
}

.pvp-img {
    color: transparent;
    width: 64px;
    height: 64px;
}

.pvp-stats-display {
    border-radius: 12px;
    background-color: rgba(0,0,0,.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 9px;
    padding: 9px;
}

.pokemon-ivs {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: nowrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.pokemon-ivs-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: 700;
    line-height: 1.2;
    border-radius: 12px;
}

.pokemon-ivs-label {
    font-weight: 700;
}

.great {
    background: #7ba7cd;
}

.ultra {
    background: #c9c089;
}

.master {
    background: #b198d3;
}

.custom {
    background: #acac98;
}

.raid {
    background: rgb(205, 165, 148);
}

@media (max-width: 530px) {
    .cp-and-level-recommended {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .pvp-stats-display {
        width: 100%;
        padding: 3px;
    }

    .pvp-img {
        width: 24px;
        height: 24px;
    }
}

@media (max-width: 360px) {
    .pvp-stats-column {
        font-size: 12px;
    }
}