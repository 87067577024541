.navbar {
    min-height: 52.13px;
    max-height: 52.13px;
    height: 52.13px;
    z-index: 40000;
    border-top: 1px solid var(--borderColor2);
    border-bottom: 1px solid var(--borderColor2);
    background-color: var(--headerBackground);
    color: var(--textColor);
    padding: 6px;
    display: flex;
    //position: sticky;
    position: fixed;
    top: 0px;
    width: 100%;
    opacity: 1;
    transition: opacity 225ms ease, visibility 225ms linear, transform 225ms linear;
}

.with-pointer {
    cursor: pointer;
}

.fake-modal {
    background-color: rgba(0, 0, 0, .33);
    position: fixed;
    height: 100%;
    width: 100vw;
    z-index: 39999;

    &.hide {
        display: none;
    }
}

.nav-visible {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}
  
.nav-hidden {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-75px);
}

.menu-visible {
    transform: translateY(0);
}
  
.menu-hidden {
    transform: translateY(-75px);
}

.options-menu {
    margin: 0 auto; /* Only centers horizontally not vertically! */
    left: 0;
    right: 0;
    position: fixed;
    max-width: 1300px;
    top: 52.13px;
    background: var(--headerBackground);
    color: var(--textColor);
    z-index: 40000;
    font-size: 14px;
    width: 100%;
    box-shadow: 0 0 2px rgba(0,0,0,.293),0 12px 11px #00000049,0 100px 80px #00000012;
    transition: transform 225ms linear;
}

.filter-menu {
    font-size: 14px;
    top: 52.13px;
    background: var(--headerBackground);
    color: var(--textColor);
    position: fixed;
    z-index: 40000;
    width: 33%;
    min-width: 320px;
    right: 0;
    box-shadow: 0 0 2px rgba(0,0,0,.293),0 12px 11px #00000049,0 100px 80px #00000012;
    transition: transform 225ms linear;
}

.filter-checkbox {
    margin: 0px 0px 0px 2px;
    top: 1px;
    position: relative;
}

.option-entry-row {
    display: flex;
    flex-direction: row;
    gap: 6px;
    padding: 6px;
    line-height: 1.25;
    color: inherit;
}

.option-entry-row-filter {
    margin: 6px 0px;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 6px;
    padding: 6px;
    line-height: 1.25;
    color: inherit;
    &.unavailable {
        border-color: var(--borderColor2);
        filter: opacity(0.33);
    }
}

.default-side-padding {
    padding: 0px 12px;
}

.fitting-content-width {
    width: fit-content;
}

.option-entry {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 6px;
    line-height: 1.25;
    color: inherit;
}

.options-li {
    width: 100%
}

.options-ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    padding-left: 0;
    grid-gap: 6px;
    gap: 6px;
}

.options-ul-filter {
    align-items: center;
}

.strong-underline {
    display: block;
    padding: 6px 0;
    border-bottom: 1px solid var(--underlineDivision);
}

.options-nav {
    display: grid;
    grid-template-columns: 1fr;
    padding: 24px 12px 24px;
    margin: auto;
    gap: 36px;
    max-width: 1300px;
    background: var(--headerBackgroundSide);
}

.options-menu.visible {
    display: block;
}

.options-menu.hidden {
    display: none;
}

.filter-menu.visible {
    display: block;
}

.filter-menu.hidden {
    display: none;
}

.navbar-section {
    display: grid;
    grid-template-columns: 31.13px 1fr 31.13px 31.13px;
    align-items: center;
    justify-content: space-between;
    grid-gap: 18px;
    gap: 18px;
    max-width: 65%;
    margin: auto;
    width: 100%;
}

.navbar-logo {
    position: relative;
    width: 31.13px;
    height: 31.13px;
    display: flex;
}

.navbar-logo-image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: transparent;
}

.navbar-dropdown-family {
    z-index: 39998;
    >div {
        border: 1px solid var(--borderColor2) !important;
        border-radius: 12px !important;
    }
    >div>div {
        //border: 1px solid var(--borderColor2) !important;
        border-radius: 12px !important;
    }
    >div>div>div {
        //border: 1px solid var(--borderColor2) !important;
        border-radius: 12px !important;
    }
    >* {
        border: 1px solid var(--borderColor2);
    }

    * {
        cursor: pointer !important;
        &:not(.cp-container) {
            color: var(--textColor) !important;
        }
        background-color: var(--popup-background-color) !important;
        //overflow: unset !important;
    }
    .img-adorner {
        background-color: rgba(0,0,0,0) !important;
    }
}

.navbar-dropdown {
    >* {
        border: 1px solid var(--borderColor2);
    }

    * {
        color: var(--textColor) !important;
        background: var(--buttonsBackground) !important;
        //overflow: unset !important;
    }
}

.navbar-dropdown-menu {
    width: 80%;
    font-size: smaller;
}

button {
    color: var(--buttonsTextColor);
    border-radius: 12px;
    font-size: inherit;
    border: 1px solid var(--buttonsTextColor);
}

.hint-container {
    display: flex;
    min-height: 16px;

    gap: 9px;

    img {
        background: unset !important;
    }
}

.navbar-menu {
    display: flex;
    order: 3;
    grid-template-columns: 30% 70%;
    height: 31.13px;
    padding: 9px;
    align-items: center;
    justify-content: center;
    background-color: var(--buttonsBackground);
    border: 1px;
    border-style: solid;
    border-color: var(--borderColor2);
    cursor: pointer;
    &:hover {
        border: 1px solid var(--buttonsBorderColor);
    }
    box-shadow: 0px 0px rgba(3, 7, 18, 0.1), 0px 1px 1px rgba(3, 7, 18, 0.08), 0px 1px 1px rgba(3, 7, 18, 0.06), 0px 3px 3px rgba(3, 7, 18, 0.04), 0px 4px 4px rgba(3, 7, 18, 0.02)
}

.contents-only {
    display: contents;
}

.heavy-weight {
    font-weight: 800;
}

.navbar-filter {
    display: flex;
    width: 31.13px;
    height: 31.13px;
    order: 4;
    padding: 9px;
    align-items: center;
    justify-content: center;
    background-color: var(--buttonsBackground);
    border: 1px;
    border-style: solid;
    border-color: var(--borderColor2);
    cursor: pointer;
    &:hover {
        border: 1px solid var(--buttonsBorderColor);
    }
    box-shadow: 0px 0px rgba(3, 7, 18, 0.1), 0px 1px 1px rgba(3, 7, 18, 0.08), 0px 1px 1px rgba(3, 7, 18, 0.06), 0px 3px 3px rgba(3, 7, 18, 0.04), 0px 4px 4px rgba(3, 7, 18, 0.02);
    &.unavailable {
        cursor: not-allowed;
        border-color: var(--borderColor2);
        filter: opacity(0.33);
    }
}
    
.selectable-descendants {
    * {
        cursor: pointer !important;
    }
}

.cogwheel {
    filter: var(--cogwheel-filter);
}

.contrast-border {
    border: 1px solid rgb(204,204,204);
}

.navbar-menu-img {
    justify-self: center;

    &.cross {
        width: 14px;
        height: 14px;
    }
}

.theme-light {
    .navbar-menu-img {
        filter: brightness(0.3);
    }
}

.search-wrapper {
    //position: relative;
}

.MuiAutocomplete-popper * {
    display: block;
}

@media (max-width: 800px) {
    .navbar-section {
        max-width: 100%;
    }
}

@media (max-width: 500px) {
    .navbar {
        min-height: 49.25px;
        max-height: 49.25px;
        height: 49.25px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .nav-hidden {
       // transform: translateY(-49.25px); /* Adjust the value based on your navbar height */
    }

    .options-nav {
        gap: 24px;
    }

    .navbar-logo {
        width: 28.25px;
        height: 28.25px;
    }

    .navbar-menu {
        display: flex;
        order: 3;
        height: 28.25px;
        span {
            display: none;
        }
    }

    .navbar-filter {
        display: flex;
        order: 4;
        height: 28.25px;
        width: 28.25px;
        span {
            display: none;
        }
    }

    .navbar-section {
        gap: 12px;
        max-width: 100%;
        grid-template-columns: 28.25px 1fr 28.25px 28.25px;
    }

    .search-wrapper {
        //position: static;
    }

    .options-menu,
    .filter-menu {
        top: 49.25px;
    }

    .navbar-dropdown-menu {
        font-size: 12px;
        width: 100%;
    }

    .hint-container {
        gap: 5px;
    }
}

@media (max-width: 351px) {
    .country-flag {
        display: none;
    }
}

@media (min-width: 500px) {
    .navbar-dropdown-family div[tabIndex="-1"]:hover:has(> div.hint-container) {
        background: var(--background-dropdowns) !important;
        *:not(img):not(.img-adorner) {
            background: var(--background-dropdowns) !important;
        }
    }

    div[tabIndex="-1"]:hover:has(> div.hint-container) {
        background: var(--selectionHover) !important;
        *:not(img):not(.img-adorner) {
            background: var(--selectionHover) !important;
        }
    }
}