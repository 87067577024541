.news-display-layout {
    display: flex;
    flex-direction: column;
    gap: 9px;
    padding-top: 9px;
}

.current-news-date {
    margin-top: 6px;
    display: flex;
    flex-direction: row;
    gap: 6px;
    justify-content: center;
}

.normal-margin-top {
    margin-top: 6px;
}

.smaller-title {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    width: 100%;
}

.spotlight-bonus {
    font-weight: 800;
    text-align: center;
    margin-top: 6px;
}

.event-img-container {
    display: flex;
    position: relative;
    justify-content: center;
}

.spotlighted-pokemon {
    pointer-events: none;
    object-fit: contain;
    position: absolute;
    z-index: 2 !important;
    filter: brightness(0.85) drop-shadow(0 1px 0 rgba(0,0,0,.15)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.15)) drop-shadow(0 -1px 0 rgba(0,0,0,.15));
    top: 0;
    left: 0;
}

.news-header-section {
    padding: 12px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;

    .event-img-itself {
        //min-height: 200px;
        border-radius: 12px;
        border: 3px solid var(--highlighted-text);
        max-width: 42%;
        width: auto;
        height: auto;
    }
    :has(>.post-notification) {
        border: 3px solid var(--red) !important;
    }
}

.spotlight-miniature-container {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
}

.news-selected {
    width: 13% !important;
    min-width: 140px !important;
    .miniature-itself {
        border: 3px solid var(--highlighted-text) !important;
    }
    :has(>.post-notification) {
        border: 3px solid var(--red) !important;
    }
}

.miniature-date {
    font-weight: 700;
    text-align: center;
}

.is-new > .miniature-date {
    color: var(--highlighted-date);
}

.post-notification {
    transform: none;
    padding: 0 25%;
    border-radius: 12px;
}

.miniature-notification {
    transform: none;
    top: 0;
    left: 0;
    padding: 0 25%;
    border-radius: 12px;
    font-size: 12px;
}

.post-miniature {
    display: flex;
    flex-direction: column;
    width: 11%;
    min-width: 100px;
}

.news-gallery {
    padding: 6px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2%;
    .miniature-itself {
        border-radius: 4px;
        border: 2px solid var(--highlighted-text);
        width: 100%;
        //max-width: 100%;
    }
    .is-new .miniature-itself {
        border: 2px solid var(--notifications-color) !important;
    }
    >:first-child {
        margin-left: auto;
    }
    >:last-child {
        margin-right: auto;
    }
}

.current-news-main-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 6px;
}

.current-news-title {
    font-size: 2.75vw;
    align-self: center;
    max-width: 75%;
    font-weight: 700;
    word-wrap: break-word;
    text-align: center;
}

.pkm-container {
    display: flex;
    flex-direction: row;
}

.from-date {
    font-weight: 700;
    font-size: 16px;
}

.to-date {
    font-weight: 700;
    font-size: 16px;
}

.date-container {
    color: var(--cp-color);
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.current-news-image-wrapper {
    img {
        //border-radius: 120px;
        //clip-path: inset(21px);
    }
}

.centered-overflow {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 1024px) {
    .miniature-date {
        font-size: 11px;
    }
}

@media (max-width: 1000px) {
    .news-header-section {
        .event-img-itself {
            max-width: 60%;
        }
    }

    .current-news-title {
        margin-top: 6px;
        align-self: unset;
        max-width: 100%;
        font-size: 5vw;
    }

    .from-date {
        font-weight: 700;
        font-size: 14px;
    }

    .to-date {
        font-weight: 700;
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .miniature-date {
        font-size: x-small;
    }

    .miniature-notification {
        font-size: unset;
    }
    
    .post-notification {
        //transform: translateY(160%) translateX(20%)
    }

    .post-miniature {
        min-width: 75px;
    }

    .news-selected {
        min-width: 100px !important;
    }
}