.images-container {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mega-container {
    position: absolute;
    bottom: 7.5%;
    right: 10%;
    width: 18%;
    height: 18%;
    min-height: 14px;
    min-width: 14px;
}

.img-clickable {
    cursor: pointer;
}

@media (min-width: 500px) {
    .img-clickable {
        &:hover&.with-img-dropShadow {
            filter: var(--hover-brightness) drop-shadow(0 1px 0 rgba(0,0,0,.15)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.15)) drop-shadow(0 -1px 0 rgba(0,0,0,.15))
        }
        &:hover {
            filter: var(--hover-brightness)
        }
    }
}

.with-img-dropShadow {
    filter: drop-shadow(0 1px 0 rgba(0,0,0,.15)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.15)) drop-shadow(0 -1px 0 rgba(0,0,0,.15))
}

.shadow-overlay {
    opacity: 1;
    position: absolute;
    bottom: 7.5%;
    left: 10%;
    width: 20%;
    height: 20%;
    min-height: 12px;
    min-width: 12px;
    z-index: 2;
    filter: drop-shadow(0 1px 0 rgba(0,0,0,.15)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.15)) drop-shadow(0 -1px 0 rgba(0,0,0,.15))
}

.padded-img {
    padding: 2px;
}

.background-absolute-img {
    object-fit: contain;
    position: absolute;
    z-index: 0 !important;
    opacity: 25%;
    top: 0;
    left: 0;
}

.minimal-padding-bottom {
    padding-bottom: 1px;
}

.no-events {
    pointer-events: none;
}

.pkm-img-name {
    z-index: 10;
    position: relative;
}

.miniature-tooltip {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 0;
    width: 100%;
    height: 100%;
    padding: 15% 15%;
    img {
        width: 130%;
        height: 130%;
        opacity: 0.3;
        max-width: unset !important;
    }
    &:empty {
        display: none;
    }
    .with-golden-border &:has(.is-master) {
        background-color: #ae98d33a;
        border: unset;
        border-radius: unset;
    }
    &:has(.is-master) {
        background-color: #ae98d33a;
        border: 2px solid #b198d3;
        border-radius: 4px;
    }

    .with-golden-border &:has(.is-great) {
        background-color: #7ba7cd67;
        border: unset;
        border-radius: unset;
    }
    &:has(.is-great) {
        background-color: #7ba7cd67;
        border: 2px solid #7ba7cd;
        border-radius: 4px;
    }

    .with-golden-border &:has(.is-ultra) {
        background-color: #c9c08934;
        border: unset;
        border-radius: unset;
    }

    &:has(.is-ultra) {
        background-color: #c9c08934;
        border: 2px solid #c9c089;
        border-radius: 4px;
    }

    .with-golden-border &:has(.is-raid) {
        img {
            width: 150%;
            height: 150%;
            opacity: 0.4;
            max-width: unset !important;
        }
        background-color: rgba(205, 165, 148, 0.151);
        border: unset;
        border-radius: unset;
    }
    
    &:has(.is-raid) {
        img {
            width: 150%;
            height: 150%;
            opacity: 0.4;
            max-width: unset !important;
        }
        background-color: rgba(205, 165, 148, 0.151);
        border: 2px solid rgb(205, 165, 148);
        border-radius: 4px;
    }
}

.background-absolute-img-grunt {
    object-fit: contain;
    position: absolute;
    z-index: 1 !important;
    /* opacity: 35%; */
    top: -40%;
    left: -40%;
    padding: 40%;
    -webkit-filter: var(--poke-border);
    filter: var(--poke-border);
}

.background-absolute-img-raid {
    object-fit: contain;
    position: absolute;
    z-index: 0 !important;
    filter: brightness(0.85);
    opacity: 15%;
    top: 0;
    left: 0;
    padding: 20px;
}

.xl-overlay {
    opacity: 1;
    position: absolute;
    bottom: 7.5%;
    right: 10%;
    width: 18%;
    height: 18%;
    filter: drop-shadow(0 1px 0 rgba(0,0,0,.15)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.15)) drop-shadow(0 -1px 0 rgba(0,0,0,.15))
}

.buddy-overlay {
    opacity: 1;
    position: absolute;
    top: 85%;
    right: 25%;
    width: 12%;
    height: 12%;
    z-index: 2;
    filter: drop-shadow(0 1px 0 rgba(0,0,0,.15)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.15)) drop-shadow(0 -1px 0 rgba(0,0,0,.15))
}

.shiny-overlay {
    opacity: 1;
    position: absolute;
    top: 75%;
    left: 100%;
    width: 33%;
    height: 33%;
    z-index: 2;
    filter: drop-shadow(0 1px 0 rgba(0,0,0,.15)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.15)) drop-shadow(0 -1px 0 rgba(0,0,0,.15))
}