.raid-img {
    filter: drop-shadow(0 1px 0 rgba(0,0,0,.5)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.5)) drop-shadow(0 -1px 0 rgba(0,0,0,.5));
}

.raid-wrapper {
    padding-top: 3px;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--borderColor2);
    margin-left: 0;
    display: flex;
    align-items: center;
    gap: 2px;
    border-radius: 12px;
    list-style: none;
    place-self: center;
    padding-bottom: 3px;
    background-color: var(--raid-wrapper-shadow);
}

.raid-img-with-contrast {
    filter: var(--contrastFilter);
}

.raid-img {
    filter: drop-shadow(0 1px 0 rgba(0,0,0,.5)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.5)) drop-shadow(0 -1px 0 rgba(0,0,0,.5));
}

.raid-rank-types {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex {
    display: flex;
    justify-content: center;
    width: fit-content;
}

.card-footer {
    color: white;
    text-shadow: -1px 0 #00000040, 0 1px #00000040, 1px 0 #00000040, 0 -1px #00000040;
    sub {
        font-size: 10px;
    }
}

.with-raid-padding {
    padding-top: 2.6px;
    padding-bottom: 2.6px;
}

@media (max-width: 360px) {
    .with-raid-padding {
        padding-top: 3.4px;
        padding-bottom: 3.4px;
    }
}