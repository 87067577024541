body {
    padding: 0;
    margin: 0;
    height: 100%;
    background: var(--bodyBackgroundColor);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100dvh;
}

#root {
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.theme-dark {
    --entry-background-shadow: rgba(0, 0, 0, .25);
    --empty-entry: rgb(36,41,47)//#26313b;
    --hover-brightness: saturate(1.1) brightness(1.2);
    --buff-panel-border: 2px solid var(--popup-background-color);
    --event-buff-panel-border: 1px solid var(--borderColor2);
    --event-buff-panel-bg: var(--popup-background-color);
    --text-shadow: -1px 0 #00000040, 0 1px #00000040, 1px 0 #00000040, 0 -1px #00000040;
    --extra-brightness: brightness(1.4);
    --scroll-background: #55585b;
    --bodyBackgroundColor: #202023;
    --gridItemsBackgroundColor: rgb(36, 41, 47);
    --ball-drop-shadow: none;
    --poke-border: drop-shadow(1px 1px 0 var(--cp-color)) drop-shadow(-0.5px -0.5px 0 var(--cp-color)) drop-shadow(1px -1px 0 var(--cp-color)) drop-shadow(-1px 1.5px 0 var(--cp-color));
    --gridItemsBackgroundColor2: rgba(0, 0, 0, 0.6);
    --rocket-softer: var(--background-shadow-soft);
    --selectionHover: #464f59;
    --headerBackground: rgb(36, 41, 47);
    --headerBackgroundSide: rgba(40, 45, 51, 0.975);
    --textColor: #eff2f7;
    --statValueColor: #eff2f7;
    --titleTextColor: #eff2f7;
    --borderColor: #424a53;
    --borderColor2: #424a53;
    --borderColorSearchBar: rgba(66, 74, 83, 0.3);
    --bannerBorderColor: #d0d0d0;
    --button-selected: #24292f;
    --contentBackground: #24292f;
    --buttonsBackground: #384049;
    --dropdownOptionBlurred: rgb(33, 39, 45);
    --dropdownOptionFocused: #26313b;
    --buttonsTextColor: #ebf4ed;
    --buttonsBorderColor: #848484;
    --tableBackground: rgba(36, 41, 47);
    --tableBackgroundHover: rgba(54, 61, 70);
    --tableHeaderSortable: rgb(198, 198, 199);
    --tableRowSelected: rgba(25, 118, 210, 0.18);
    --popup-background-color: #26313b;
    --underlineDivision: rgba(255, 255, 255, 0.25);
    --lighter-tone-text: #ffffff;
    --event-special-font: #d7d7d7;
    --titleMoveTextColor: #eff2f7;
    --cp-color: rgb(255, 189, 0);
    --triple-color: rgb(255, 139, 0);
    --colorScheme: dark;
    --filter: invert(1);
    --filter-inverse: none;
    --background-shadow: rgba(0, 0, 0, .5);
    --background-shadow-soft: rgba(0, 0, 0, .25);
    --background-shadow-baby-soft: rgba(0, 0, 0, .1);
    --buffBackground: #384049;
    --type-Bug: #85b430;
    --type-Dark: #4f4c5f;
    --type-Dragon: #035094;
    --type-Electric: #c09b2d;
    --type-Fairy: #c966bf;
    --type-Fighting: #a02c3b;
    --type-Fire: #be7510;
    --type-Flying: #7188b0;
    --type-Ghost: #574e87;
    --type-Grass: #248b46;
    --type-Ground: #aa6641;
    --type-Ice: #6ea19f;
    --type-Normal: #727475;
    --type-Poison: #b03a9a;
    --type-Psychic: #cc595d;
    --type-Rock: #9f9052;
    --type-Steel: #548a9e;
    --activePerkFilter: contrast(0) sepia(0.6) brightness(1.5) var(--event-filter);
    --type-Water: #3f7dba;
    --event-filter: drop-shadow(0 1px 0 rgba(0,0,0,.5)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.5)) drop-shadow(0 -1px 0 rgba(0,0,0,.5));
    --contrastFilter: brightness(0.9);
    --raid-wrapper-shadow: rgba(0, 0, 0, .38);
    --stat-label-color: rgb(183 166 118);
    --background-dropdowns: #1C252C;
    --higher-level: rgb(210, 42, 42);
    --header-main-color: #374653;
    --shadow-header-color: #3c3845;
    --header-main-text-color: #eff2f7;
    --notifications-color-text: #202122;
    --notifications-color: rgb(255, 189, 0);
    --highlighted-text: rgb(255, 189, 0);
    --highlighted-date: rgb(255, 189, 0);
    --special-header-color: #7d5656;
}
//5e7793
.theme-light {
    --highlighted-date: #202122;
    --shadow-header-color: #7b7097;
    --special-header-color: #855f5f;
    --highlighted-text: #5e7793;
    --header-main-color: #5e7793;
    --notifications-color-text: #000000;
    --notifications-color: rgb(255, 189, 0);
    --header-main-text-color: rgb(255, 255, 255);
    --activePerkFilter: contrast(0) brightness(0.6);
    --higher-level: rgb(148, 0, 0);
    --lighter-tone-text: #202122;
    --poke-border: drop-shadow(1px 1px 0 var(--cp-color)) drop-shadow(-0.5px -0.5px 0 var(--cp-color)) drop-shadow(1px -1px 0 var(--cp-color)) drop-shadow(-1px 1.5px 0 var(--cp-color));
    --event-special-font: #4C4C4C;
    --event-filter: drop-shadow(0 1px 0 rgba(0,0,0,.5)) drop-shadow(1px 0 0 rgba(0,0,0,.5)) drop-shadow(-1px 0 0 rgba(0,0,0,.5)) drop-shadow(0 -1px 0 rgba(0,0,0,.5));
    --entry-background-shadow: hsla(0,0%,100%,.8);
    --empty-entry: rgb(235, 235, 235);
    --cp-color: #e19261;
    --triple-color: #f34612e1;
    --hover-brightness: saturate(1.1) brightness(1.05);
    --stat-label-color: #e78547;
    --buff-panel-border: 1px solid var(--borderColor2);
    --event-buff-panel-border: 1px solid var(--borderColor2);
    --text-shadow: none;
    --extra-brightness: none;
    --scroll-background: #b3b3b3;
    --bodyBackgroundColor: #f0f0e5;
    --statValueColor: gray;
    --ball-drop-shadow: drop-shadow(4px 4px 4px var(--cp-color));
    --gridItemsBackgroundColor: rgb(240, 240, 240);
    --gridItemsBackgroundColor2: rgb(240, 240, 240);
    --rocket-softer: unset;
    --selectionHover: rgb(205, 205, 205);
    --headerBackground: rgb(244, 244, 244);
    --headerBackgroundSide: rgba(247, 249, 249, 0.975);
    --textColor: #202122;
    --titleTextColor: #eff2f7;
    --borderColor: #424a53;
    --borderColor2: rgb(204, 204, 204);
    --borderColorSearchBar: rgba(204, 204, 204, 0.5);
    --bannerBorderColor: #d0d0d0;
    --button-selected: rgb(235, 235, 235);
    --contentBackground: #fff;
    --buttonsBackground: rgb(250, 250, 250);
    --dropdownOptionBlurred: rgb(235, 235, 235);
    --dropdownOptionFocused: rgb(223, 223, 223);
    --buttonsTextColor: #202122;
    --buttonsBorderColor: #848484;
    --tableBackground: #f6f6f6;
    --event-buff-panel-bg: var(--bodyBackgroundColor);
    --tableBackgroundHover: #dcdcdc;
    --tableHeaderSortable: rgb(140, 140, 140);
    --tableRowSelected: rgba(25, 118, 210, 0.18);
    --popup-background-color: #f6f6f6;
    --underlineDivision: rgba(40, 40, 40, 0.25);
    --titleMoveTextColor: #fff;
    --colorScheme: light;
    --filter: none;
    --filter-inverse: invert(1);
    --background-shadow: hsla(0,0%,100%,.8);
    --background-shadow-soft: hsla(0,0%,100%,.8);
    --background-shadow-baby-soft: hsla(0,0%,100%,.8);
    --background-dropdowns: #ffffff;
    --buffBackground: #EBEBEB;
    --type-Bug: #92c92b;
    --type-Dark: #575467;
    --type-Dragon: #0566bc;
    --type-Electric: #ffce3e;
    --type-Fairy: #eb8ee2;
    --type-Fighting: #d23c50;
    --type-Fire: #f09516;
    --type-Flying: #90aadc;
    --type-Ghost: #776bb6;
    --type-Grass: #35ab5d;
    --type-Ground: #d97845;
    --type-Ice: #96d9d6;
    --type-Normal: #9d9fa0;
    --type-Poison: #d24fbb;
    --type-Psychic: #f76e73;
    --type-Rock: #cdba6c;
    --type-Steel: #538b9f;
    --type-Water: #5092d5;
    --contrastFilter: brightness(1);
    --raid-wrapper-shadow: rgba(0, 0, 0, .18);
}